import React,{FC,useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'


type BtnT ={
   tabClick:any 
   buttons:any
   filterTable:any
   date:string
   setFakeLoadBtn:any
}

const Btn: FC<BtnT> = ({tabClick,buttons,filterTable,date,setFakeLoadBtn}) => {
   const params = useParams()
   const[active, setActive]=useState(buttons[0].text)
   
   const btnClick=(e:any)=>{
      setActive(e.target.id)
      //window.scrollTo(0,(div.scrollHeight + div.scrollTop));
      
         setFakeLoadBtn(true)
 
     
   }
   
   useEffect(()=>{
      
      setActive(buttons[0].text)


   },[params,date])
   
 
   return (

      <div >
            <div className={'pointer-events-auto flex flex-nowrap overflow-auto '}>
               {             
               buttons.map((item:any)=>(

                  
                     <button key={item.id}  id={item.text} onClick={(e)=>{tabClick(e);btnClick(e);filterTable(e)}}  className={item.text===active?'whitespace-nowrap pointer-events-none transition duration-150 ease-out md:ease-in px-5 py-3 md:py-2 bg-blue text-white font-semibold  uppercase':'bg-white whitespace-nowrap transition duration-150 ease-out md:ease-in px-5 py-3 md:py-2 hover:bg-blue hover:text-white text-blue font-semibold  uppercase'}>{item.text}</button>

                  


                  ))
               }
            </div>
      </div>
            


   )
}

export default Btn