import React,{FC,useState, useEffect} from 'react'
import s from './styles/header.module.css'
import { Link } from 'react-router-dom'
import logo from './images/MICRO FOOTBALL.svg'

const header = {
   logo:logo,
   link:'/',
   closeBtn:'Закрыть',
   news:['Новости','/news'],
   sports:[
      {
         id:'1',
         text:'USSR INDOOR Серия 1',
         link:'/ussr_indoor_series_1'
      },
      {
         id:'2',
         text:'USSR INDOOR Серия 2',
         link:'/ussr_indoor_series_2'
      },
      {
         id:'3',
         text:'ACL INDOOR 5x5',
         link:'/acl_indoor_5x5'
      },
      {
         id:'4',
         text:'RPL 6x6',
         link:'/rpl_6x6'
      }
]
}
const Header:FC = () => {
   const[strapiheader, setStrapiheader]=useState<any>(null)
   const[isLoaded,setIsloaded]=useState(false)
   const[open, setOpen]=useState(false)
   const menuClick=()=>{
      setOpen(prev=>!prev)
   }


   const [activePage,setActivePage]=useState(document.location.pathname)
   const linkClick = (e:any) => {
      setActivePage(e.target.id)
      
   }
   
   useEffect(()=>{
      fetch("https://microfootball.ru/app/api/header?populate[logo][populate][logo][fields]=url&populate[news][fields]&populate[sports][fields]")
      
      .then(res=> res.json())
      .then((result)=>{
         setStrapiheader(result.data.attributes)
         setIsloaded(true)
      })
      .catch((err) => {
         console.error(err)
   })
   },[])
   

   if(!isLoaded){
      return null
   }else{
   return (
		<header className={s.body}>
         <div className='fakediv h-8 md:h-0'></div>
			<div className={s.cntnr}>
				<div className={s.row}>
               <div className="-mr-4 lg:-mr-0 ">
                  <Link  to={strapiheader.logo.link} ><img  id={header.link} onClick={(e)=>linkClick(e)} className='w-full ' src={'https://microfootball.ru/app'+strapiheader.logo.logo.data.attributes.url} alt="logo" /></Link>
					</div>

               

               <div className='w-full flex justify-end'>
                  <button onClick={menuClick} className=' block md:hidden'>
                     <img src='https://microfootball.ru/app/uploads/burger_50ac4e13f02b4da917179087c50fddcf_2e1dd9920a.svg?updated_at=2022-08-30T09:33:24.104Z' alt="menu" />
                  </button>
                  <ul className={open ? `${s.ulActive} ${s.ulDef}` : `${s.ul} ${s.ulDef}`}>

                     {/* <li >
                        {
                           activePage==strapiheader.news.link ?
                           <Link  to={strapiheader.news.link}><p id={strapiheader.news.link} onClick={(e)=>linkClick(e)} className='ease duration-200 after:ease after:duration-200 relative after:absolute after:top-full after:left-0 after:h-1 after:w-full after:bg-blue-light after:content-[""] bg-blue-dark py-3 md:py-5  px-1  lg:px-3'>{strapiheader.news.text}</p></Link>

                           :
                        <Link  to={strapiheader.news.link}><p id={strapiheader.news.link} onClick={(e)=>linkClick(e)} className='ease duration-200 after:ease after:duration-200 relative after:absolute after:top-full after:left-0 after:h-1 after:w-full hover:after:bg-blue-light after:content-[""] hover:bg-blue-dark py-3 md:py-5  px-1  lg:px-3'>{strapiheader.news.text}</p></Link>

                        }
                     </li> */}
                     {
                        strapiheader.sports.map((item:any)=>(
                           <li key={item.id} >
                              {
                                 activePage==item.link ? 
                                 <Link  to={item.link}><p id={item.link} onClick={(e)=>linkClick(e)} className='ease duration-200 after:ease after:duration-200 relative after:absolute after:top-full after:left-0 after:h-1 after:w-full after:bg-blue-light after:content-[""] bg-blue-dark py-3 md:py-5  px-1  lg:px-3 text-end'>{item.text}</p></Link>

                                 :
                                 <Link  to={item.link}><p id={item.link} onClick={(e)=>linkClick(e)} className='ease duration-200 after:ease after:duration-200 relative after:absolute after:top-full after:left-0 after:h-1 after:w-full hover:after:bg-blue-light after:content-[""] hover:bg-blue-dark py-3 md:py-5  px-1  lg:px-3 text-end'>{item.text}</p></Link>

                              }
                           </li>
                           
                        ))
                     }
                  <button onClick={menuClick} className='absolute bg-blue-light py-3 px-6 rounded-b top-full block md:hidden'>
                        {header.closeBtn}
                  </button>
                  
                  </ul>

               </div>


				</div>
			</div>
		</header>
   )
}
}
export default Header