import React,{FC,useState,useEffect} from 'react'

import Sport from '../../components/Sport/Sport'
import { SportsT, ProtocolT } from '../../types/cmsTypes'
import { useParams } from 'react-router-dom'
import { useLoadingContext } from "react-router-loading";
import {Helmet} from "react-helmet";
import s from "./styles/pages.module.css"

// const pages:{[key:string]:any} = {
//       header:[
//             {
//                   id:'1',
//                   text:'Дата',
//                   img:calendar
//             },
//             {
//                   id:'2',
//                   text:'Время',
//                   img:null
//             },
//             {
//                   id:'3',
//                   text:'Название',
//                   img:null
//             },
//             {
//                   id:'4',
//                   text:'Матч',
//                   img:null 
//             },
//             {
//                   id:'5',
//                   text:'Тайм 1',
//                   img:null
//             },
//             {
//                   id:'6',
//                   text:'Тайм 2',
//                   img:null
//             },
//             {
//             id:'7',
//             text:'Статус',
//             img:table
//             },
            
//       ],
//       divisions:{
//             ussr_indoor_series_1 :

      
//             {
//             linkId:'897',
//             title:'USSR INDOOR Серия 1',
//             bg:bg1,
//             logo:ussr,
//             button:[
//             {
//                   id:'1',
//                   text:'Результаты'
      
      
//             },
//             {
//                   id:'2',
//                   text:'ПРЕДСТОЯЩИЕ МАТЧИ'
      
      
//             },
//             {
//                   id:'3',
//                   text:'LIVE-МАТЧИ'
      
      
//             },
//             {
//             id:'4',
//             text:'РЕГЛАМЕНТ'
      
      
//       },
      
//       ],
      
      
      
//       },
//       ussr_indoor_series_2 :
      
            
//             {
//             linkId:'1473',
//             title:'USSR INDOOR Серия 2',
//             bg:bg2,
//             logo:ussr,
//             button:[
//             {
//                   id:'1',
//                   text:'Результаты'
      
      
//             },
//             {
//                   id:'2',
//                   text:'ПРЕДСТОЯЩИЕ МАТЧИ'
      
      
//             },
//             {
//                   id:'3',
//                   text:'LIVE-МАТЧИ'
      
      
//             },
//             {
//             id:'4',
//             text:'РЕГЛАМЕНТ'
      
      
//       },
      
//       ],
      
      
      
//       },
//       rpl_6x6 :
      
            
//             {
//             linkId:'169,477',
//             title:'RPL 6x6',
//             bg:rplbg,
//             logo:rpl,
//             button:[
//             {
//                   id:'1',
//                   text:'Результаты'
      
      
//             },
//             {
//                   id:'2',
//                   text:'ПРЕДСТОЯЩИЕ МАТЧИ'
      
      
//             },
//             {
//                   id:'3',
//                   text:'LIVE-МАТЧИ'
      
      
//             },
//             {
//             id:'4',
//             text:'РЕГЛАМЕНТ'
      
      
//       },
      
//       ],
      
//       },
//       acl_indoor_5x5 :
      
            
//             {
//             linkId:'2564,2565,2715,3275,3276',
//             title:'ACL INDOOR 5x5',
//             bg:aclbg,
//             logo:acl,
//             button:[
//             {
//                   id:'1',
//                   text:'Результаты'
      
      
//             },
//             {
//                   id:'2',
//                   text:'ПРЕДСТОЯЩИЕ МАТЧИ'
      
      
//             },
//             {
//                   id:'3',
//                   text:'LIVE-МАТЧИ'
      
      
//             },
//             {
//             id:'4',
//             text:'РЕГЛАМЕНТ'
      
      
//       },
      
//       ],
      
      
//       }
//       }

      

// }


const Page:FC = () => {
      let params = useParams();
      const [fetchUrl1,setFetchUrl1] = useState<any>(`https://microfootball.ru/app/api/divisions?filters[slug][$eq]=${params.id}&populate[logo][fields]=url&populate[backGround][fields]=url`) 
      const fetchUrl2=`https://microfootball.ru/app/api/sport-table?populate[header][fields]&populate[button][fields]`
      
      const[strapiTable,setStrapiTable] = useState<any>()
      const[fakeLoad, setFakeLoad]=useState<any>()
      const[strapiPages,setStrapiPages] =useState<any>()

      const  dayNum = String(new Date().getDate()).padStart(2, '0')
      const  monthNum = String(new Date().getMonth() + 1).padStart(2, '0')
      const  yearNum = String(new Date().getFullYear())
      let newDateforInput = `${dayNum}.${monthNum}.${yearNum}`
      const [date,setDate]=useState<string>(newDateforInput)
      

      const loadingContext = useLoadingContext();
      const loading = async () => {
            loadingContext.done();
      }
      
      const selectedDate=(newDate:string)=>{
            setDate(newDate)
      }
      
      const [error, setError] = useState(null);
      const [isLoaded, setIsLoaded] = useState(false);
      const [tableLoaded,setTableLoaded] = useState(false)
      const [infoForTable,setInfoForTable] = useState<any>()
      
      const [table, setTable]=useState<ProtocolT[]>([])
      const [defaultTable, setDefaultTable]=useState<ProtocolT[]>([])
      const filterTable = (e:any) => {
            

            if(e.target.id == strapiTable.button[1].text ){
                  setTable(defaultTable.filter((i:any)=>i.status=="Завершен"))
            }
            else if(e.target.id == strapiTable.button[2].text ){
                  setTable(defaultTable.filter((i:any)=>i.status=="Не начат"))
            }
            else if(e.target.id == strapiTable.button[3].text ){
                  setTable(defaultTable.filter((i:any)=>i.status==""))
            }
            else{
                  setTable(defaultTable)
            }
      }

      //useEffect(()=>{window.scrollTo(0,0)},[])
       //`https://book.bigsports.ru/api/joomsports/?date=${date}&league=${result.data[0].attributes.linkId}&per_page=100&page=1&status=1`

       //https://microfootball.ru/app/api/scout-calendar/league=897,49023,49024,49025,49026,49027/date=12.10.2023/status=4/page=1/per_page=100/
      useEffect(() => {
            let fetchUrl3:string='' 
            
            setTableLoaded(false)


            fetch(fetchUrl1)

            .then(res=>res.json())
            .then((result)=>{
                  setStrapiPages(result.data[0].attributes)
                  fetchUrl3=`https://microfootball.ru/app/api/scout-calendar/league=${result.data[0].attributes.linkId}/date=${date}/status=4/page=1/per_page=100/?apikey=NXbK9dDSoGlGx2TiNFh6po9evLh2iWk9`
                  setInfoForTable(strapiPages)
            })
            .then(()=>{
                  fetch(fetchUrl2)
                  .then(res=>res.json())
                  .then((result)=>{
                        setStrapiTable(result.data.attributes)
                  })
            })
            .then(()=>{
                  fetch(fetchUrl3)
                  .then(res => {
                        if(res.ok && res.json) return res.json()
                        throw new Error('Ошибка')
                  })
                  .then(json => {
                        if(json) {
                              const table = filterResults(json.data)
                              
                              // setInfoForTable(prevProps => ({
                              //       ...prevProps,
                              //       tablerow: table
                              // }))
                              let tableArrSc = table.map((el:any)=>({...el,homeLogo:el.homeLogo.replace('scout.bigsports','sc.bigsports'),awayLogo:el.awayLogo.replace('scout.bigsports','sc.bigsports')}))
                              
                              setDefaultTable(tableArrSc)
                              setTable(tableArrSc)
                              
      
                        }
                  })
                  .then(()=>{
                        
                        setIsLoaded(true)
                        setTableLoaded(true)
                        setFakeLoad(true)
                        loading()
                        setTimeout(()=>{
                              
                              if(!isLoaded){
                              window.scrollTo(0,0)
                        }
                        },50)
                        
                  })
            })
            
            .catch((err) => {
                  setError(err);
                  console.error(err)
            })

            //const filtered = infoForTable..filter()
            
           // Страницы USSR INDOOR СЕРИЯ 1/USSR INDOOR СЕРИЯ 2/ACL INDOOR 5X5/RPL 6X6 делаем динамическим роутером по названию, по аналогии с новостями
           // ссылки формата /ussr_indoor_series_1 проверку делаем по ссылке
           
      }, [params, date])

      const filterResults = (data:any[]) => {
            let tr:any = []
            
            data.map(item=>(

               tr.push({winner:item.score.home_score>item.score.away_score ? item.homePartName : item.score.home_score<item.score.away_score ? item.awayPartName : 'equal', id:item.id, date:item.match_date.split(' '),homeTeam:item.homePartName,awayTeam:item.awayPartName,homeLogo:item.homePartEmblem,awayLogo:item.awayPartEmblem,homeScore:item.score.home_score,awayScore:item.score.away_score,status:item.statusText,times:Object.values(item.stages), statusImg:item.statusText=='Завершен'?'https://microfootball.ru/app/uploads/end_8501aa073b918d14eff0f31ffa361ce0_bd06c086b8.svg?updated_at=2022-08-25T14:21:09.768Z':item.statusText==''?'https://microfootball.ru/app/uploads/live_1c6daf91a0630122b55a8212753485cb_7044706424.svg?updated_at=2022-08-25T14:21:09.538Z':null})


               
            ))

            return tr
      }

      if (error) {
      return (
      <>
      <Helmet>
                  <title>{window.location.pathname}</title>
      </Helmet>
            <div  className='text-black'>
            
            
      Ошибка</div>
      </>)
      } else if (!isLoaded) {
            
      return(

            <>
            <Helmet>
                  <title>{window.location.pathname}  | MicroFootball</title>
            </Helmet>
            <div  className='w-full h-full md:px-4 flex items-center justify-center'>
            
                  <div className={s.ldsroller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            
            </div>
            </>
      
      )
      } else {


      return (
            <>
            <Helmet>
                  <title>{params.id?strapiPages.title : window.location.pathname}  | MicroFootball</title>
            </Helmet>

            <div  className='w-full h-full md:px-4'>
            
                  <div className='absolute w-full  -z-10 -top-20 -bottom-20 left-0 flex items-start bg-blue-sodark'>
                        <img className='hidden sm:block h-screen w-full  object-cover' src={'https://microfootball.ru/app'+strapiPages.backGround.data[0].attributes.url} alt="bg" />
                        <img className=' w-full sm:hidden object-cover object-top mt-20' src={'https://microfootball.ru/app'+strapiPages.backGround.data[1].attributes.url} alt="bg" />
                  </div>
                  <Sport fakeLoad={fakeLoad} setFakeLoad={setFakeLoad} strapiTable={strapiTable} strapiPages={strapiPages} tableLoaded={tableLoaded} filterTable={filterTable} data={infoForTable} table={table} selectedDate={selectedDate} header={strapiTable.header}/>
            </div>
            </>
            
      )
}
}
export default Page