import React,{FC,useEffect,useState} from 'react'
import s from './styles/footer.module.css'
import { Link } from 'react-router-dom'

// const footer = {
//    logo:logo,
//    powered:[powB,powC],
//    poweredLink:'https://itsports.io/',
//    link:'/',
//    news:['Новости','/news'],
//    sports:[
//       {
//          id:'1',
//          text:'USSR INDOOR Серия 1',
//          link:'/ussr_indoor_series_1'
//       },
//       {
//          id:'2',
//          text:'USSR INDOOR Серия 2',
//          link:'/ussr_indoor_series_2'
//       },
//       {
//          id:'3',
//          text:'ACL INDOOR 5x5',
//          link:'/acl_indoor_5x5'
//       },
//       {
//          id:'4',
//          text:'RPL 6x6',
//          link:'/rpl_6x6'
//       }
// ]
// }
const Footer:FC = () => {
   const fetchUrl='https://microfootball.ru/app/api/footer?populate[logo][populate][logo][fields]=url&populate[news][fields]&populate[sports][fields]&populate[powered][populate][logo][fields]=url'
   const[strapifooter, setStrapifooter]=useState<any>(null)
   const[isLoaded,setIsloaded]=useState(false)
   
   useEffect(()=>{
      fetch(fetchUrl)
      
      .then(res=> res.json())
      .then((result)=>{
         setStrapifooter(result.data.attributes)
         setIsloaded(true)
      })
      .catch((err) => {
         console.error(err)
   })
   },[])

   if(!isLoaded){
      return null
   }else{
   return (
		<footer className={s.body}>
			<div className={s.cntnr}>
				<div className={s.row}>
               <div className='pb-6 md:pb-12'>
                  <Link to={strapifooter.logo.link}><img src={'https://microfootball.ru/app'+strapifooter.logo.logo.data.attributes.url} alt="logo" /></Link>
					</div>

               


					<ul className='flex-col md:flex-row flex items-center pb-6 md:pb-12 text-center lg:text-base text-sm ' >
                  {/* <li className='mr-2  lg:mr-4  pb-2 md:pb-0'>
                     <Link to={strapifooter.news.link}><p className='px-0 lg:px-2'>{strapifooter.news.text}</p></Link>
                  </li> */}
                  {
                     strapifooter.sports.map((item:any)=>(
                        <li key={item.id} className='mr-2 pb-2 md:pb-0 lg:mr-4 last:mr-0  last:pb-0'>
                           <Link  to={item.link}><p className='px-0 lg:px-2'>{item.text}</p></Link>
                        </li>
                        
                     ))
                  }
               
					</ul>
               <div className={s.attention_attention} >
                  {strapifooter.attention&&strapifooter.attention}
               </div>

               <div className='relative w-full h-12 '>
                  <div className='z-10 absolute top-0 left-1/2 -translate-x-1/2 w-max h-max'>
                     <img  src={'https://microfootball.ru/app'+strapifooter.powered.logo.data[0].attributes.url} alt="powered" />

                  </div>
                  
                  <div  className='cursor-pointer ease-out duration-200 z-20 opacity-0 hover:opacity-100 absolute top-0 left-1/2 -translate-x-1/2 w-max h-max'>
                     <a target="_blank" href={strapifooter.powered.link}><img  src={'https://microfootball.ru/app'+strapifooter.powered.logo.data[1].attributes.url} alt="powered" /></a>
                     
                  </div>
               </div>
				</div>
			</div>
		</footer>
   )
 }
}

export default Footer