import React from 'react'
import { Outlet } from 'react-router-dom'
import HeaderContainer from '../Header/Header'
import Footer from '../Footer/Footer'
import s from './styles/layout.module.css'
import bgr from '../../images/Frame 23.svg'
import bgl from '../../images/Frame 22.svg'
const Layout = () => {
	
	return (
		<div className={s.body}>
			<HeaderContainer />
			<main className={s.main + ' relative'}>
			<div className='-z-10 absolute -top-20 left-0 right-0 -bottom-5 flex justify-between items-start'>
				<div  className='h-full w-full justify-start flex items-end'>
					<img src="https://microfootball.ru/app/uploads/Frame_22_c233a799c5.svg?updated_at=2022-08-25T10:42:13.713Z" alt="bg" />
				</div>
				<div className='h-full w-full justify-end flex items-start'>
					<img src="https://microfootball.ru/app/uploads/Frame_23_a31f868cad.svg?updated_at=2022-08-25T10:42:13.191Z" alt="bg" />
				</div>
					
					
				</div>
				<div className={s.cntnr }>
					<Outlet />
				</div>
			</main>
			<Footer />
		</div>
	)
}

export default Layout
