import React,{FC} from 'react'
import s from './styles/main.module.css'
import {LiveT} from '../../types/cmsTypes'
import { Link } from 'react-router-dom'
import liveImg from './images/signal-01.svg'
type LivesT={
   live: any
   lives:any
}



const Live: FC<LivesT> = ({live,lives}) => {
   

  return (
   
    <div className={s.body}>
      <div className='md:px-3 px-3'>
      <h2 className='pt-6 pb-8'>
         {live.title}
      </h2>


      {
      lives.length < 2 ? 


      <div className='-mx-2 flex-col md:flex-row  flex items-center justify-center flex-wrap 2xl:flex-nowrap'>
         
            {
               lives.length > 0 &&
               <Link className='p-2 w-full md:w-1/2 2xl:w-full ' to={lives[0].link}>
            <div >
            <div  className=' rounded overflow-hidden '>
               <div className='px-4 border-b border-grey-light flex items-center justify-between font-semibold bg-white-semidark text-black'>
                  <div className='flex items-center py-3'>
                     <img className='animate-opas' src="https://microfootball.ru/app/uploads/signal_01_1be8e1db99.svg?updated_at=2022-08-25T10:40:01.693Z" alt="i" />
                     <p className='ml-2 text-red uppercase'>
                        {live.status}
                     </p>
                  </div>
                  
                  <div>
                     {lives[0].time}
                  </div>
               </div>


               <div className='flex items-center justify-around bg-white-semidark '>
                  <div className='flex flex-col justify-start h-28 py-5 w-1/2 px-1'>
                     <div className='flex items-center justify-center pb-1'>
                        <img className='h-10 w-10'  src={lives[0].home_team.image.replace('scout.bigsports','sc.bigsports')} alt="logo" />
                     </div>
                     
                     <p className='text-black leading-4 text-center'>{lives[0].home_team.name}</p>
                  </div>
                  <div className='pb-3'>
                     <p className='text-blue-light text-2xl font-semibold '>{lives[0].score.home}:{lives[0].score.away}</p>
                  </div>
                  <div className='flex flex-col justify-start h-28 py-5 w-1/2 px-1'>
                     <div className='flex items-center justify-center pb-1'>
                        <img className='h-10 w-10' src={lives[0].away_team.image.replace('scout.bigsports','sc.bigsports')} alt="logo" />
                     </div>
                     
                     <p className='text-black leading-4 text-center'>{lives[0].away_team.name}</p>
                  </div>
               </div>

               <div className='bg-blue-light py-3 text-center text-lg'>
                  {lives[0].division_name}
               </div>
            </div>
            </div>

               </Link>

            }


            <div  className='p-1 w-full md:w-1/2 2xl:w-full hidden md:flex items-center justify-center h-32'>
               <p className='text-center font-semibold text-xl'>
                  {live.ifNoMatch}
               </p>
            </div>

         
      </div>

      :


     
         <div className='-mx-2 flex-col md:flex-row  flex items-center justify-between flex-wrap 2xl:flex-nowrap'>
         {lives.map((item:any,index:any)=>(
         <Link className='p-2 w-full md:w-1/2 2xl:w-full '  key={index}  to={item.link}>
            <div >
            <div  className=' rounded overflow-hidden '>
               <div className='px-4 border-b border-grey-light flex items-center justify-between font-semibold bg-white-semidark text-black'>
                  <div className='flex items-center py-3'>
                     <img className='animate-opas' src="https://microfootball.ru/app/uploads/signal_01_1be8e1db99.svg?updated_at=2022-08-25T10:40:01.693Z" alt="i" />
                     <p className='ml-2 text-red uppercase'>
                        {live.status}
                     </p>
                  </div>
                  
                  <div>
                     {item.time}
                  </div>
               </div>


               <div className='flex items-center justify-around bg-white-semidark '>
                  <div className='flex flex-col justify-start h-28 py-6 w-1/2 px-1'>
                     <div className='flex items-center justify-center pb-1'>
                        <img className='h-10 w-10' src={item.home_team.image.replace('scout.bigsports','sc.bigsports')} alt="logo" />
                     </div>
                     
                     <p className='text-black leading-4 text-center'>{item.home_team.name}</p>
                  </div>
                  <div className='pb-3'>
                     <p className='text-blue-light text-2xl font-semibold '>{item.score.home}:{item.score.away}</p>
                  </div>
                  <div className='flex flex-col justify-start h-28 py-5 w-1/2 px-1'>
                     <div className='flex items-center justify-center pb-1'>
                        <img className='h-10 w-10' src={item.away_team.image.replace('scout.bigsports','sc.bigsports')} alt="logo" />
                     </div>
                     
                     <p className='text-black leading-4 text-center'>{item.away_team.name}</p>
                  </div>
               </div>

               <div className='bg-blue-light py-3 text-center text-lg'>
                  {item.division_name}
               </div>
            </div>
            </div>
         </Link>
         ))}


         {   
         
         (lives.length<4 ) && 
            <div  className={s.ifNoMatch+' p-1 w-full md:w-1/2 2xl:w-full  items-center justify-center h-32'}>
               <p className='text-center font-semibold text-xl'>
               {live.ifNoMatch}
               </p>
            </div>
            
            }
      </div>
         
      
      
      }
      </div>
      
      
   </div>
  )
}

export default Live