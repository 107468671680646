import React, { lazy, Suspense,useState } from 'react'
import { Routes, Route } from "react-router-loading";
import './App.css';
import news1 from './components/Layout/images/news1.jpg'
import news2 from './components/Layout/images/news2.jpg'
import Main from './pages/Main/Main'
//import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Layout from './components/Layout';
import Page from './pages/SportPage/Page';
import { topbar } from "react-router-loading";


topbar.config({
    autoRun: true,
    barThickness: 4,
    barColors: {
        0: '#000000',
        .3: '#448BDE',
        1.0: '#448BDE'
    },
    shadowBlur: 10,
    shadowColor: '#f2f2f2',
    className: 'topbar'
});


// const Layout = lazy(() => import('./components/Layout/LoaderContainer'))

// const Page = lazy(() => import('./pages/SportPage/Page'))
const News = lazy(() => import('./pages/News/News'))
const Paper = lazy(() => import('./pages/ChosenNews/Paper'))


function App() {

 




const routes = [
	{
		id: 1,
		path: '/',
		element: <Layout />,
		children: [
			{
				id: 2,
				path: '/',
				element: <Main/>,
			},

      {
				id: 3,
				path: '/news',
				element: <News/>,
			},

      {
				id: 4,
				path: '/:id',
				element: <Page />,
			},

      
      {
				id: 5,
				path: `/news/:id`,
				element: <Paper/>,
			},

    
		],
	},
]



  return (
    <Suspense>
    <Routes>
      {routes.map(mainRoute => {
        const {
          path: mainPath,
          element: mainElement,
          children: mainChildren,
          id: mainId,
        } = mainRoute

        return (
          <Route path={mainPath} element={mainElement} key={mainId} >
            {mainChildren.map(childRoute => {
              const {
                path: childPath,
                element: childElement,
                id: childId,
              } = childRoute

              return (
                <Route loading
                  path={childPath}
                  element={childElement}
                  key={childId}
                />
              )
            })}
          </Route>
        )
      })}
    </Routes>
  </Suspense>
  );
}

export default App;
